/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type CheckboxProps = {
  checked?: boolean;
  error?: any;
  name?: string;
  register?: Function;
  required?: boolean;
  theme: 'orange';
  label?: string;
  clickHandler?: (event?: any) => void;
};

const Checkbox = ({ checked = false, theme, clickHandler, label = '' }: CheckboxProps) => {
  return (
    <div css={[style, themes[theme]]}>
      <div className={`checkbox--wrap ${checked ? 'checked' : ''}`} onClick={clickHandler}>
        <div className="checkbox--element" />
      </div>
      {label.length > 0 && (
        <p onClick={clickHandler} className="checkbox--label">
          {label}
        </p>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  theme: 'orange',
};

const style = css`
  display: flex;
  .checkbox--wrap {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid #cfcfcf;
  }

  .checkbox--element {
    transform: rotate(45deg);
    height: 13px;
    width: 7px;
    border-bottom: 2px solid #cfcfcf;
    border-right: 2px solid #cfcfcf;
    margin-left: 5px;
  }
  .checkbox--label {
    margin-left: 6px;
    color: #555555;
    font-size: 16px;
    margin-top: -1px;
  }
`;
const themes = {
  orange: css`
    .checkbox--wrap {
      &.checked {
        border-color: #ff5500;
        .checkbox--element {
          border-bottom: 2px solid #ff5500;
          border-right: 2px solid #ff5500;
        }
      }
    }
  `,
};
export default Checkbox;
