const WarningIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.751 16.574 14.542 4.11a2.33 2.33 0 0 0-.869-.813A2.472 2.472 0 0 0 12.496 3c-.413 0-.819.102-1.177.297a2.329 2.329 0 0 0-.87.813L3.242 16.574a2.014 2.014 0 0 0 .106 2.083c.207.308.494.56.835.729.34.17.721.25 1.105.236h14.418a2.28 2.28 0 0 0 1.11-.23c.343-.168.633-.42.84-.729a2.004 2.004 0 0 0 .096-2.089z"
        fill="#fff"
      />
      <path d="M11.5 7.4h2.192l-.365 5.846h-1.462L11.5 7.4z" fill="#BB203D" />
      <rect x="11.683" y="14.707" width="1.827" height="1.827" rx=".913" fill="#BB203D" />
    </svg>
  );
};

export default WarningIcon;
