const SuccessIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 11a7 7 0 0 0-7-7h-2a7 7 0 0 0-7 7v2a7 7 0 0 0 7 7h2a7 7 0 0 0 7-7v-2zm-7.01-1.974a1 1 0 0 1-.99.99 1 1 0 0 1-.99-.99 1 1 0 0 1 .99-.99 1 1 0 0 1 .99.99zm-.165 1.474V16h-1.65v-5.5h1.65z"
        fill="#999"
      />
      <circle cx="12.5" cy="12" r="9" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.218 8.5 1.033 1.033-5.733 5.733L8 11.748l1.033-1.034L11.52 13.2 16.218 8.5z"
        fill="#32C882"
      />
    </svg>
  );
};

export default SuccessIcon;
