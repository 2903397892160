import { css, cx, keyframes } from '@emotion/css';
import media from '../../shared/mediaQuery';
import SuccessIcon from '../../public/icon/SuccessIcon';
import WarningIcon from '../../public/icon/WarningIcon';

export type ToastType = 'basic' | 'warning' | 'success';

export type ToastProps = {
  message: string;
  type?: ToastType;
};

export const defaultToast: ToastProps = { message: '', type: 'basic' };

const slideToast = keyframes`
  0% {
    ${media.desktop} {
      top: -52px;
    }
    top: -48px;
  }
  30%, 70% {
    ${media.desktop} {
      top: 52px;
    }
    top: 48px;
  }
  100% {
    ${media.desktop} {
      top: -52px;
    }
    top: -48px;
  }
  `;

const types = {
  basic: css`
    background-color: #333333f3;
  `,
  success: css`
    background-color: #32c882;
    svg {
      margin-right: 6px;
    }
  `,
  warning: css`
    background-color: #bb203df3;
    svg {
      margin-right: 6px;
    }
  `,
};

const toastWrapper = css`
  animation: ${slideToast} 3000ms ease-in-out;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  ${media.desktop} {
    transform: translate(-50%, -50%);
    width: calc(100% - 360px);
    border-radius: 4px;
    left: 50%;
    height: 52px;
  }
  ${media.mobile} {
    transform: translateY(-100%);
    width: 100%;
    height: 48px;
    left: 0;
  }
  &.active {
    display: flex;
  }
  .toast--message {
    font-size: 14px;
    color: #fff;
    font-family: 'Pretendard-Regular';
    text-align: center;
    line-height: 48px;
  }
`;

export const Toast = ({
  message,
  visible,
  type = 'basic',
}: {
  message: string;
  visible: boolean;
  type?: ToastType;
}) => {
  return (
    <div className={cx(toastWrapper, types[type], { active: visible })}>
      {type === 'warning' && <WarningIcon />}
      {type === 'success' && <SuccessIcon />}
      <p className="toast--message">{message}</p>
    </div>
  );
};
